//data
const tradData = require("./../data/lang.json");

const getTrad = (id, lang) => {
    let final = "";
    if(tradData.hasOwnProperty(id)){
        final = tradData[id][lang]
    }

    return final;
}

export default getTrad;