import querystring from 'querystring';

import { getData } from './index';
import {
	THING_ERROR,
	THING_RESET,
	THING_ALL,
	THING_ALL_PENDING
} from './types';

export function getThings() {
	let params = {
		skip:0,
		limit:30
	}

	const url = "/thing/all?" + querystring.stringify(params);

	return dispatch => getData(THING_ALL, THING_ALL_PENDING, THING_ERROR, false, url, dispatch);
}

export function resetThing() {
	return dispatch => dispatch({ type: THING_RESET });
}