// react
import React, { useRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
// internal
import GetTrad from "../utils/GetTrad";
// actions
import { choiceSet } from './../../actions/action_global';
// style
import "./Choice.css"
import cycle from "./../../css/img/choice_cycle.png";
import couch from "./../../css/img/choice_couch.png";

const Choice = (props) => {

	let history = useHistory();
	const dispatch = useDispatch()

	let setChoice = (c) => {
		dispatch(choiceSet(c))
		history.push("/map")
	}
	
	return (
		<div
			className={"choice-container"}
		>
			<div
				className="choice-previous"
				onClick={() => history.push("/")}
			></div>
			<h1>Verdwenen Zwinhavens</h1>
			<div className="choice-choice" onClick={() => setChoice("cycle")}>
				<img src={cycle} />
				<p><GetTrad id="choiceCycle" /></p>
			</div>
			<div className="choice-choice" onClick={() => setChoice("couch")}>
				<img src={couch} />
				<p><GetTrad id="choiceCouch" /></p>
			</div>
		</div>
	)
}

export default Choice