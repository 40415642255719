// react
import React, { useRef, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Marker } from 'react-map-gl';
// utils
import { getSubffile } from "./../../utils"
// style
import "./Zmarker.css"

const Zmarker = (props) => {

	const [image, setImage] = useState("")
	const global = useSelector(state => state.global)

	useEffect(() => {
		setImage(getSubffile(props.thing.ffile, "miniature"));
	}, [])

	return (
		<Marker
			longitude={props.longitude}
			latitude={props.latitude}
			className={"marker-z-"+props.thing.type+""}
		>
			<div
				className={
					"marker-"+props.thing.type+" "
					+(props.selected && props.selected._id === props.thing._id ? "marker-"+props.thing.type+"-selected" : "")+""
				}
				onClick={() => {
					if(props.selected && props.selected._id === props.thing._id){
						props.setSelected(null)
					}else{
						console.log("Selecting "+props.thing._id)
						props.setSelected(props.thing)
					}
				}}
			>
				{global.choice == "couch" ?
					<div
						className="marker-questionmark"
						style={(global.choice == "couch") && {
							backgroundImage:"url('"+image+"')",
							transform: "scale(0.7)"
						}}
					></div> :
					<div
						className="marker-questionmark"
					></div>
				}
			</div>
		</Marker>
	)
}

export default Zmarker