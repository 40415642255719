// react
import React, { useRef, useEffect, useState } from "react";
// utils
import { getSubffile } from "./../../utils"
import { GetTrad, GetDistance } from "./../utils"
// style
import "./ZthingPoi.css"

const ZthingPoi = (props) => {

	const [thing, setThing] = useState(null)
	const [deploy, setDeploy] = useState(false)
	const [expand, setExpand] = useState(false)
	const [image, setImage] = useState("")

	useEffect(() => {
		if(props.superSelected && (props.superSelected.type === "poi" || props.superSelected.type === "borne" || props.superSelected.type === "bornemini")){
			setThing(props.superSelected)
			setDeploy(true)
			setImage(getSubffile(props.superSelected.ffile, "miniature"))
		}else{
			setDeploy(false)
		}
		
	}, [props.superSelected])

	let btnClose = () => {
		props.setSuperSelected(null)
	}

	let launchGps = () => {
		props.setGps(true);
		props.setSuperSelected(null)
	}

	return (
		<div
			className={"zthing-poi "+(!deploy ? "zthing-poi-hidden" : "")+""}
		>
			<div
				className={"zthing-poi-content "+(expand ? "zthing-poi-content-expand" : "")+""}
			>
				<div
					className="zthing-poi-miniature"
					style={{
						backgroundImage:"url('"+image+"')"
					}}
				>
					<div
						className="zthing-poi-close"
						onClick={btnClose}
					></div>
				</div>
				<p className={"zthing-poi-type "+(thing && (thing.type === "borne" && thing.type === "bornemini") && "zthing-poi-type-borne")+""}>{thing && thing.type === "poi" ? <GetTrad id={"typepoi"} /> : <GetTrad id={"typeborne"} />}</p>
				{thing && <p className={"zthing-poi-title"}>{thing && <GetTrad trad={thing.name} />}</p>}
				<p className="zthing-poi-description">{thing && <GetTrad trad={thing.caption} />}</p>
				<div className="zthing-poi-distance">{thing && <React.Fragment><GetDistance latitude={thing.location.coordinates[1]} longitude={thing.location.coordinates[0]} /> km</React.Fragment>}</div>
				<div className="zthing-poi-btns">
					<div
						className="zthing-poi-btn"
						onClick={() => launchGps()}
					><GetTrad id={"launchDirection"} /></div>
					<div
						className="zthing-poi-btn zthing-poi-btn-full"
						onClick={() => setExpand(!expand)}
					>{expand ? <GetTrad id={"seeless"} /> : <GetTrad id={"seemoremore"} />}</div>
				</div>
				<div className="zthing-poi-content-content">
					{thing && <GetTrad trad={thing.content} />}
				</div>
			</div>
		</div>
	)
}

export default ZthingPoi