// react
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import { createStore, applyMiddleware } from 'redux';
import reduxThunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import { PersistGate } from 'redux-persist/lib/integration/react';
import storage from 'redux-persist/lib/storage';
import reportWebVitals from './reportWebVitals';
import reducers from './reducers/index';
// component
import App from './App';
// style
import './css/index.css';

const persistConfig = {
	key: 'root',
	storage
};

const createStoreWidthMiddleware = applyMiddleware(reduxThunk)(createStore);
const persistedReducer = persistReducer(persistConfig, reducers);
const store = createStoreWidthMiddleware(persistedReducer, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());
const persistor = persistStore(store);

ReactDOM.render(
	<Provider store={store}>
		<PersistGate loading="loading..." persistor={persistor}>
			<App />
		</PersistGate>
	</Provider>,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
