import {
	GLOBAL_LANGUAGE_SET,
	GLOBAL_CHOICE_SET,
	GLOBAL_COORDS_SET
} from './types';

export function languageSet(language) {
	return dispatch => dispatch({
		type: GLOBAL_LANGUAGE_SET,
		payload:language
	});
}

export function choiceSet(choice) {
	return dispatch => dispatch({
		type: GLOBAL_CHOICE_SET,
		payload:choice
	});
}

export function coordsSet(coords) {
	return dispatch => dispatch({
		type: GLOBAL_COORDS_SET,
		payload: coords
	});
}