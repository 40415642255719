import {
	THING_ERROR,
	THING_RESET,
	THING_ALL,
	THING_ALL_PENDING
} from '../actions/types';

const initialState = {
	thing: null,
	things: [],
	message: null,
	error: null,
	pendingAll: false
};

const thingReducer = (state = initialState, action) => {
	switch (action.type) {
		case THING_ALL:
			return {
				...state,
                things:action.payload.things,
				pendingAll: false
			};
		case THING_ALL_PENDING:
			return {
				...state,
				pendingAll: true
			};
		case THING_ERROR:
			return {
				...state,
				message: null,
				error: action.payload.message
			};
		case THING_RESET:
			return {
				...initialState
			};
		default:
			return state;
	}
}

export default thingReducer