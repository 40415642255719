// react
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
//data
const tradData = require("./../../data/lang.json");

const GetTrad = (props) => {

    const [trad, setTrad] = useState("")

	const global = useSelector(state => state.global)

    useEffect(() => {
        if(props.hasOwnProperty("id")){
            if(tradData.hasOwnProperty(props.id)){
                setTrad(tradData[props.id][global.language])
            }
        }else if(props.hasOwnProperty("trad")){
            console.log(props.trad)
            if(props.trad !== undefined){
                if(props.trad.translations){
                    for(let t of props.trad.translations){
                        if(t.langage === global.language){
                            setTrad(t.value)
                        }
                    }
                }
            }
        }        
	}, [global.language, props.id, props.trad])

    return (
		<React.Fragment>
            {trad}
        </React.Fragment>
    )
}

export default GetTrad;