// react
import React, { useRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
// mapbox
/*import mapboxgl from '!mapbox-gl' // eslint-disable-line import/no-webpack-loader-syntax
import 'mapbox-gl/dist/mapbox-gl.css'*/
import MapboxDirections from "@mapbox/mapbox-gl-directions/dist/mapbox-gl-directions"
import '@mapbox/mapbox-gl-directions/dist/mapbox-gl-directions.css'
import ReactMapGL, { GeolocateControl, NavigationControl, Source, Layer } from 'react-map-gl';
// actions
import { getThings, resetThing } from './../../actions/action_thing';
import { coordsSet } from "../../actions/action_global";
// intern
import Zmarker from "./../zmarker/Zmarker"
import ZthingPopup from "./../zthing/ZthingPopup"
import ZthingObject from "./../zthing/ZthingObject"
import ZthingPoi from "./../zthing/ZthingPoi"
// style
import "./Zmap.css"
import test from "./../../css/mapbox/style.json"
import geojson from "./cyclemap"
import testimage from "./../../css/img/pourbus/pourbus_02.jpg"

const accessToken = process.env.REACT_APP_MAPBOX_API_KEY

const Zmap = () => {
	
	// directions
	var directions = new MapboxDirections({
		accessToken: process.env.REACT_APP_MAPBOX_API_KEY,
		unit: 'metric',
		profile: 'mapbox/cycling'
	});

	/*
	
	map.current.addControl(directions, 'top-left');*/

	let history = useHistory();

	const dispatch = useDispatch()
	const thing = useSelector(state => state.thing)
	const global = useSelector(state => state.global)

	useEffect(() => {
		dispatch(resetThing())
		dispatch(getThings())
	}, [])

	const [watchid, setWatchid] = useState(null)

	useEffect(() => {

		let getPosition = function (position) {

			//latitude = position.coords.latitude;
			//longitude = position.coords.longitude;

			console.log(position.coords);

			dispatch(coordsSet(position.coords));
		}

		let errorPosition = function (error) {
			var info = "Erreur lors de la géolocalisation : ";
			switch (error.code) {
				case error.TIMEOUT:
					info += "Timeout !";
					break;
				case error.PERMISSION_DENIED:
					info += "Vous n’avez pas donné la permission";
					break;
				case error.POSITION_UNAVAILABLE:
					info += "La position n’a pu être déterminée";
					break;
				case error.UNKNOWN_ERROR:
					info += "Erreur inconnue";
					break;
			}
			console.log(info);
			//document.getElementById("infoposition").innerHTML = info;
		}

		if (navigator.geolocation) {
			setWatchid(navigator.geolocation.watchPosition(
				getPosition,
				errorPosition,
				{
					maximumAge: 0,
					enableHighAccuracy: true
				}
			))
		} else {
			alert("Ce navigateur ne supporte pas la géolocalisation");
		}
	}, []);

	const coordsStart = {
		latitude: 51.2996962,
		longitude: 3.3054355,
		zoom: 10
	}

	const [viewport, setViewport] = useState(coordsStart);
	const [pourbus, setPourbus] = useState(false)

	const [selected, setSelected] = useState(null)
	const [superSelected, setSuperSelected] = useState(null)
	const [gps, setGps] = useState(false);

	const [map, setMap] = useState(null);
	const [direction, setDirection] = useState(null);

	useEffect(() => {
		if(map){
			console.log(map)
			// directions
			var directions = new MapboxDirections({
				accessToken: process.env.REACT_APP_MAPBOX_API_KEY,
				unit: 'metric',
				profile: 'mapbox/cycling',
				flyTo: false
			});
			
			map.addControl(directions);

			setDirection(directions)
		}
	}, [map])

	useEffect(() => {
		if(direction && gps === false){
			direction.removeRoutes();
		}
	}, [gps])

	const markers = React.useMemo(() => thing && thing.things.map(
		thing => {
			return (
				<Zmarker
					thing={thing}
					key={thing._id}
					longitude={thing.location.coordinates[0]}
					latitude={thing.location.coordinates[1]}
					selected={selected}
					setSelected={setSelected}
				/>
			)
		}
	), [thing, selected]);
	
	const geolocateControlStyle = {
		display: "inline-block",
		position: "absolute",
		right: 20,
		top: 50
	};

	const navControlStyle = {
		display: "inline-block",
		position: "absolute",
		right: 20,
		top: 100
	};

	const layerStyle = {
		id: 'point',
		type: 'line',
		paint: {
			"line-color": "#503d22",
			"line-width": 4
		}
	  };

	return (
		<React.Fragment>
			<div className="zmap">
				<div className="pourbus-container">
					<div className="pourbus-switch">
						<span
							onClick={() => setPourbus(!pourbus)}
							className={pourbus && "pourbus-switch-active"}
						>Pourbus</span>
					</div>
				</div>
				<ZthingObject
					selected={selected}
					superSelected={superSelected}
					setSuperSelected={setSuperSelected}
					setGps={setGps}
				/>
				<ZthingPoi
					selected={selected}
					superSelected={superSelected}
					setSuperSelected={setSuperSelected}
					setGps={setGps}
				/>
				<ZthingPopup
					selected={selected}
					setSelected={setSelected}
					setSuperSelected={setSuperSelected}
					direction={direction}
					setGps={setGps}
					gps={gps}
				/>
				<div
					className="zmap-previous"
					onClick={() => history.push("/")}
				></div>
				<ReactMapGL
					{...viewport}
					mapboxApiAccessToken={accessToken} //accessToken
					width="100%"
					height="100%"
					onViewportChange={(viewport) => setViewport(viewport)}
					mapStyle={test}
					ref={ref => ref && setMap(ref.getMap())}
				>
					<Source id="my-data" type="geojson" data={geojson}>
						<Layer {...layerStyle} />
					</Source>
					{pourbus &&
						<Source
							id="overlay"
							type="image"
							url={testimage}
							coordinates={[
								[3.086125, 51.3267556],
								[3.5966333333333336, 51.4788833],
								[3.7098250000000004, 51.3266611],
								[3.19955, 51.1786611]
							]}
						>
							<Layer
								id='overlay'
								type='raster'
								source='overlay'
								paint={{ 'raster-opacity': 1.0} }
							/>
						</Source>
					}
					{global.choice === "cycle" && 
						<GeolocateControl
							positionOptions={{enableHighAccuracy: true}}
							trackUserLocation={true}
							//auto
						/>}
					<NavigationControl />
					<div style={{position:"absolute", right:"00px", top: "200px"}}>
						<div className="mapboxgl-ctrl mapboxgl-ctrl-group">
							<button
								className="mapboxgl-ctrl-icon mapboxgl-ctrl-reinit"
								type="button"
								title="Zoom In"
								onClick={() => setViewport(coordsStart)}
							>
								<span className="mapboxgl-ctrl-icon" aria-hidden="true"></span>
							</button>
						</div>
					</div>
					{markers}
				</ReactMapGL>
			</div>
		</React.Fragment>
	)
}

export default Zmap;