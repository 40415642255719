// redux
import { combineReducers } from 'redux';
// reducers
import reducerGlobal from './reducer_global';
import reducerThing from './reducer_thing';

const appReducer = combineReducers({
	global: reducerGlobal,
	thing: reducerThing
});

const rootReducer = (state, action) => {
	return appReducer(state, action);
};

export default rootReducer;