// react
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
// npm
import LatLon from 'geodesy/latlon-nvector-spherical.js';

const GetDistance = (props) => {

    const [distance, setDistance] = useState(0)

	const global = useSelector(state => state.global)

    useEffect(() => {
        if(global.coords){
            let originLatLon = new LatLon(props.latitude, props.longitude, 0)
            let newDistance = Number(originLatLon.distanceTo(new LatLon(global.coords.latitude, global.coords.longitude, 0))/1000).toFixed(1)
            setDistance(newDistance)
        }else{

        }
	}, [global.coords, props.latitude, props.longitude])

    return (
		<React.Fragment>
            {distance}
        </React.Fragment>
    )
}

export default GetDistance;