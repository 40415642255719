// react
import React, { useRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// actions
import { languageSet } from './../../actions/action_global';
// style
import "./Zlanguage.css"

const Zlanguage = (props) => {

	const dispatch = useDispatch()

	let setLanguage = (l) => {
		dispatch(languageSet(l))
		props.setDisplayPopup(false)
	}
	
	return (
		<div
			className={"zlanguage-container"}
		>
			<div className="zlanguage-popup">
				<p onClick={() => setLanguage("en")}>Continue in English</p>
				<p onClick={() => setLanguage("fr")}>Continuer en Français</p>
				<p onClick={() => setLanguage("de")}>Continue in Deutsh</p>
				<p onClick={() => setLanguage("nl")}>Continue in NL</p>
			</div>
		</div>
	)
}

export default Zlanguage