//Redux
import React, { Component } from 'react';

class PublicAppRedirect extends Component {

	componentDidMount(){
		window.location.href = "https://app.verdwenen-zwinhavens.be/";
	}

    render() {
        return (
            <React.Fragment>
				Redirect to https://app.verdwenen-zwinhavens.be/ 
            </React.Fragment>
        );
    }
}

export default PublicAppRedirect