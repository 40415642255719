// react
import React from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
// components
import Intro from './components/intro/Intro';
import Choice from './components/choice/Choice';
import Zmap from './components/zmap/Zmap';
import PublicAppRedirect from './components/public/PublicAppRedirect';

function App() {
	return (
		<Router>
			<Switch>
				<Route exact path="/p/app">
					<PublicAppRedirect />
				</Route>
				<Route exact path="/map">
					<Zmap />
				</Route>
				<Route exact path="/choice">
					<Choice />
				</Route>
				<Route exact path="/">
					<Intro />
				</Route>
			</Switch>
		</Router>
	);
}

export default App;