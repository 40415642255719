// react
import React, { useRef, useEffect, useState } from "react";
import { useSelector } from "react-redux";
// utils
import { getSubffile, getTrad } from "./../../utils"
import { GetTrad, GetDistance } from "./../utils"
// style
import "./Zthing.css"

const ZthingPopup = (props) => {

	const global = useSelector(state => state.global)

	const [thing, setThing] = useState(null)
	const [deploy, setDeploy] = useState(false)
	const [type, setType] = useState(null)
	const [image, setImage] = useState("")

	useEffect(() => {
		console.log(props.selected)

		if(props.selected === null){
			setDeploy(false)
			setTimeout(function(){
				if(thing === null){
					setThing(props.selected)
				}
			}, 1000)

			props.setGps(false)

		}else{
			setDeploy(true)
			setThing(props.selected)
		}

		if(props.selected){
			setImage("")
			switch(props.selected.type){
				case "object": setType(getTrad("typeobject", global.language)); break
				case "poi": setType(getTrad("typepoi", global.language)); break
				case "borne": setType(getTrad("typeborne", global.language)); break
				case "bornemini": setType(getTrad("typeborne", global.language)); break
			}

			setImage(getSubffile(props.selected.ffile, "miniature"));
		}
	}, [props.selected])

	useEffect(() => {
		if(props.selected && props.gps){
			if(props.direction){
				props.direction.setOrigin([global.coords.longitude, global.coords.latitude])
				props.direction.setDestination(props.selected.location.coordinates)
			}
		}
	}, [props.gps]);

	let btnClose = () => {
		props.setSelected(null)
	}

	let btnAction = () => {
		//console.log("click action")
		if(thing.type === "poi" || thing.type === "object" || thing.type === "borne" || thing.type === "bornemini"){
			props.setSuperSelected(thing)
		}
	}

	let btnGpsStop = () => {
		props.setGps(false)
	}

	return (
		<div
			className={"zthing-popup "+(!deploy ? " zthing-popup-hidden " : "")+""+(props.gps && " zthing-popup-gps")+""+""}
		>
			{props.gps && <div class="zthing-popup-gps-panel">
				<p>Itinéraire</p>
			</div>}
			{thing && props.gps ? 
				<div
					className="zthing-popup-btn zthing-popup-btn-stop"
					onClick={() => btnGpsStop()}
				><GetTrad id={"gpsStop"} /></div>
			: 
				<div
					className="zthing-popup-btn"
					onClick={btnAction}
				><GetTrad id={"seemore"} /></div>
			}
			<div className="zthing-popup-distance">{thing && <React.Fragment><GetDistance latitude={thing.location.coordinates[1]} longitude={thing.location.coordinates[0]} /> km</React.Fragment>}</div>
			<div
				className={"zthing-popup-illustration"}
				style={{
					backgroundImage:"url('"+image+"')"
				}}
			>
				{!props.gps && <div
					className="zthing-popup-close"
					onClick={btnClose}
				></div>}
			</div>
			<div className="zthing-popup-content">
				{thing && <p className={"popup-type popup-type-"+thing.type}>{type}</p>}
				{thing && <p className={"popup-title"}><GetTrad trad={thing.name} /></p>}
			</div>
		</div>
	)
}

export default ZthingPopup