// react
import React, { useRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
// internal
import Zlanguage from "./../zlanguage/Zlanguage"
import GetTrad from "./../utils/GetTrad"
// style
import "./Intro.css"

const Intro = () => {
	
	let history = useHistory();
	const [displayPopup, setDisplayPopup] = useState(false)

	useEffect(() => {

	}, [])

	return (
		<React.Fragment>
			{displayPopup ? <Zlanguage setDisplayPopup={setDisplayPopup} /> : null}
			<div className="intro-background">
				<div className="intro-title">
					<h1 className="bold">Verdwenen Zwinhavens</h1>
					<p><GetTrad id="subtitle" /></p>
					<div className="btn" onClick={() => history.push("/choice")}><GetTrad id="btnstart" /> <span className="arrowright"></span></div>
				</div>
				<div className="intro-cycle"></div>
				<div className="intro-footer">
					<div className="intro-choice bold" onClick={() => setDisplayPopup(true)}><GetTrad id="langs" /></div>
					<p>©Westtoer/UGent/Timescope</p>
				</div>
			</div>
		</React.Fragment>
	)
}

export default Intro;