// react
import React, { useRef, useEffect, useState } from "react";
// npm
import "@google/model-viewer";
// utils
import { getSubffile } from "./../../utils"
import GetTrad from "./../utils/GetTrad"
// style
import "./ZthingObject.css"

const ZthingObject = (props) => {

	const [thing, setThing] = useState(null)
	const [deploy, setDeploy] = useState(false)
	const [glb, setGlb] = useState(null)
	const [usdz, setUsdz] = useState(null)
	const [expand, setExpand] = useState(false)

	useEffect(() => {
		if(props.superSelected && props.superSelected.type === "object"){
			setThing(props.superSelected)
			setDeploy(true)
			//setImage(getSubffile(props.superSelected.ffile, "miniature"))

			let url = getSubffile(props.superSelected.ffile, "glb");

			fetch(url).then(r => r.blob()).then((blob)=>{
				setGlb(URL.createObjectURL(blob))
			});

			let urlusdz = getSubffile(props.superSelected.ffile, "usdz");
			setUsdz(urlusdz)

			/*fetch(urlusdz).then(r => r.blob()).then((blob)=>{
				setUsdz(URL.createObjectURL(blob))
			});*/

		}else{
			setDeploy(false)
		}
		
	}, [props.superSelected])

	let btnClose = () => {
		props.setSuperSelected(null)
	}

	let launchGps = () => {
		props.setGps(true);
		props.setSuperSelected(null)
	}

	return (
		<div
			className={"zthing-object "+(!deploy ? "zthing-object-hidden" : "")+""}
		>
			<div
				className="zthing-object-close"
				onClick={btnClose}
			></div>
            <div className="zthing-object-title">{thing && <GetTrad trad={thing.name} />}</div>
			<div className="zthing-object-btns">
				<div
					className="zthing-object-btn zthing-object-btn-direction"
					onClick={() => launchGps()}
				><span></span> <GetTrad id="launchDirection" /></div>
				{/*<a href={usdz} rel="ar" className="zthing-object-btn zthing-object-btn-ar"><span></span> <GetTrad id="ar" /></a>*/}
			</div>
			<div
				className={"zthing-object-viewer "+(expand ? "zthing-object-viewer-expand" : "")+""}
			>
				<model-viewer
					style={{
						marginTop: "20px",
						width:"100%",
						height:"100%",
						zIndex:"5000",
						display:"inline-block"
					}}
					src={glb}
					camera-controls
					ar
					ar-modes="scene-viewer webxr quick-look"
					//ios-src={}
				>
				</model-viewer>
			</div>
			<div
				className={"zthing-object-content "+(expand ? "zthing-object-content-expand" : "")+""}
			>
				<div onClick={() => setExpand(!expand)} className="zthing-object-content-link"></div>
				{thing && <GetTrad trad={thing.content} />}
			</div>
		</div>
	)
}

export default ZthingObject