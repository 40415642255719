import {
	GLOBAL_LANGUAGE_SET,
	GLOBAL_CHOICE_SET,
	GLOBAL_COORDS_SET
} from '../actions/types';

const initialState = {
	language: "nl", // Default language
	choice: "cycle",
	coords: {
		latitude:0,
		longitude:0
	}
};

const globalReducer = (state = initialState, action) => {
	switch (action.type) {
		case GLOBAL_LANGUAGE_SET:
			return {
				...state,
				language: action.payload
			};
		case GLOBAL_CHOICE_SET:
			return {
				...state,
				choice: action.payload
			};
		case GLOBAL_COORDS_SET:
			return {
				...state,
				coords: {
					latitude: action.payload.latitude,
					longitude: action.payload.longitude
				}
			}
		default:
			return state;
	}
}

export default globalReducer