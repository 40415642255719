import axios from 'axios';

// URL list
export const API_URL = process.env.REACT_APP_API_URL;
export const CLIENT_ROOT_URL = process.env.REACT_APP_CLIENT_ROOT_URL;

// Get Request
export function getData(action, pending, errorType, isAuthReq, url, dispatch) {
    const requestUrl = API_URL + url;
    let headers = {};

    if(pending !== null){
        dispatch( { type:pending } );
    }
    
    if (isAuthReq) {
        headers = {
            headers: {
                Authorization: localStorage['token']
            }
        };
    }
    
    return axios
    .get(requestUrl, headers)
    .then(response => {
        dispatch({
            type: action,
            payload: response.data
        });
        return { response };
    })
    .catch(error => {
        return errorHandler(dispatch, error, errorType);
    });
}

export function errorHandler(dispatch, error, type, requestData) {
    console.error('Error type: ', type);
    console.error(error);
  
    let errorMessage = error && error.response ? error.response.data : error || 'unknown error';

    // NOT AUTHENTICATED ERROR
	if (error && ((error.hasOwnProperty("status") && error.status === 401) || (error.hasOwnProperty("response") && error.response.status === 401))) {
        console.log(errorMessage);
		return dispatch(null)
        //return dispatch(logoutUser(errorMessage));
	}
    
    dispatch({
        type,
        payload: errorMessage,
        requestData // put request data in response so reducers know what is it about
    });
  
    return { error };
}