// api
const API_URL = process.env.REACT_APP_API_URL;

const getSubffile = (ffile, channel) => {
    let final = "";
	
	if(ffile && ffile.data && ffile.data.subffiles && ffile.data.subffiles.length > 0){
		for(let f of ffile.data.subffiles){
			if(f.status === "active"){
				if(f.channel === channel){
					final = API_URL+"/subffile/get/" + f.path.split("\\")[1];
				}
			}
		}
	}

    return final;
}

export default getSubffile;